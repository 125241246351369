import { ComponentProps } from "react";
import { useSnackbar } from "notistack";
import { useModal, UpdateModal } from "../../../components";
import { Absence } from "../../../API";
import { useAbsenceLib } from "../../../lib";
import { RequestModal } from "./RequestModal";

// This hook is solely responsible for the management of the various Time Off modals.
// Here we can manage the modals for the various actions (CRUD) that we want to perform.
export const useRequestModals = () => {
  const { createAbsenceRecord, updateAbsenceRecord, deleteAbsenceRecord } =
    useAbsenceLib();
  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string, error = false) => {
    enqueueSnackbar(message, { variant: error ? "error" : "success" });
  };

  const postAction = (
    message: string,
    isError: boolean,
    callback: () => void
  ) => {
    showSnackbar(message, isError);
    callback();
  };

  const { showModal: showCreateModal, hideModal: hideCreateModal } = useModal<
    ComponentProps<typeof RequestModal>,
    { maxAmount: number }
  >({
    component: RequestModal,
    data: {
      title: "Request Time Off",
      onSubmit: ({ data }) =>
        createAbsenceRecord(data, {
          onSuccess: () =>
            postAction(
              "Your time off request has been submitted.",
              false,
              hideCreateModal
            ),
          onError: () =>
            postAction(
              "Error requesting time off. Please try again in a few seconds",
              true,
              hideCreateModal
            ),
        }),
      testID: "create-request",
    },
  });

  const { showModal: showUpdateModal, hideModal: hideUpdateModal } = useModal<
    ComponentProps<typeof RequestModal>,
    { row: Absence; maxAmount: number }
  >({
    component: RequestModal,
    data: {
      title: "Update Time Off",
      onSubmit: ({ data }) =>
        updateAbsenceRecord(
          {
            ...data,
            id: data.id || "0",
          },
          {
            onSuccess: () =>
              postAction(
                "Your time off request has been submitted.",
                false,
                hideUpdateModal
              ),
            onError: () =>
              postAction(
                "Error requesting time off. Please try again in a few seconds",
                true,
                hideUpdateModal
              ),
          }
        ),
      testID: "update-request",
    },
  });

  const { showModal: showDeleteModal, hideModal: hideDeleteModal } = useModal<
    ComponentProps<typeof UpdateModal>,
    { row: Absence }
  >({
    component: UpdateModal,
    data: {
      title: "Remove Time Off Request",
      label: "Remove",
      description: "Are you sure you want to remove this entry?",
      onUpdate: ({ id }) =>
        deleteAbsenceRecord(id, {
          onSettled: () =>
            postAction(
              "Your time off request has been deleted.",
              false,
              hideDeleteModal
            ),
          onError: () =>
            postAction(
              "Error deleting time off. Please try again in a few seconds",
              true,
              hideDeleteModal
            ),
        }),
    },
  });

  return {
    showCreateModal,
    showUpdateModal,
    showDeleteModal,
  };
};
