import React, { ComponentProps } from "react";
import { Box } from "@mui/material";
import {
  GridColDef,
  GridFooter,
  GridInputSelectionModel,
  GridRowsProp,
  GridSelectionModel,
  GridValidRowModel,
  GridSortDirection,
} from "@mui/x-data-grid";
import {
  EmptyState,
  ErrorState,
  LoadingState,
  Toolbar,
  ToolbarProps,
} from "./components";
import { StyledDataGrid } from "./styles";
import { useColumns, RowAction } from "./useColumns";
import { Footer } from "./components/Footer";

export enum PaginationMode {
  CLIENT = "client",
  SERVER = "server",
}

type Props = {
  /** The Column Definitions. */
  columns: GridColDef[];
  /** The Data to display within the grid. */
  data: GridRowsProp;
  /** The Row actions to be displayed in the final column. (optional) */
  rowActions?: RowAction[];
  /** If true, a loading overlay is displayed. (optional) */
  loading?: boolean;
  /** The Empty State to display when no data is available. (optional) */
  emptyState?: ComponentProps<typeof EmptyState>;
  /** The Error State to display when in error. (optional) */
  errorState?: ComponentProps<typeof ErrorState>;
  /**
   * Used to hide columns on initial load. (optional)
   * Especially useful for large data-sets.
   * Hidden columns can be shown by clicking the column header and will always be present in exported CSV's.
   */
  columnVisibility?: { [key: string]: boolean };
  /** Pagination config. */
  pagination?: {
    /** The index of the current page. Default: 1. */
    page?: number;
    /** Callback handler called when navigating between pages. */
    onPageChange?: (page: number) => void;
    /** The number of rows in one page. */
    pageSize?: number;
    /** Callback handler called when changing page size. */
    onPageSizeChange?: (pageSize: number) => void;
    /** Options to display in rows per page dropdown.  */
    rowsPerPageOptions?: number[];
    /** Pagination can be processed on the server or client-side. Default: client. */
    paginationMode?: PaginationMode;
    rowCount?: number;
  };
  hideFooterPagination?: boolean;
  /** If set, the default pagination footer is replaced with a custom footer containing this content. */
  customFooterContent?: string;
  /** Callback for cell/row editing. */
  processRowUpdate?:
    | ((
        newRow: GridValidRowModel,
        oldRow: GridValidRowModel
      ) => GridValidRowModel | Promise<GridValidRowModel>)
    | undefined;
  testID: string;
  disableColumnMenu?: boolean;
  disableSelectionOnClick?: boolean;
  sortModel?: [
    {
      field: string;
      sort: GridSortDirection;
    }
  ];
} & ToolbarProps &
  (
    | {
        checkboxSelection: true;
        selectionModel: GridInputSelectionModel;
        onSelectionModelChange: (selectionModel: GridSelectionModel) => void;
      }
    | {
        checkboxSelection?: never;
        selectionModel?: never;
        onSelectionModelChange?: never;
      }
  );

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [100];

export const DataGrid = ({
  columns,
  data,
  rowActions,
  loading,
  emptyState,
  errorState,
  columnVisibility,
  pagination,
  processRowUpdate,
  hideColumnSelector,
  hideExport,
  header,
  checkboxSelection,
  selectionModel,
  onSelectionModelChange,
  csvOptions,
  testID,
  disableColumnMenu,
  hideFooterPagination,
  customFooterContent,
  disableSelectionOnClick,
  sortModel,
}: Props) => {
  const cols = useColumns({ columns, rowActions });

  const {
    page,
    onPageChange = undefined,
    pageSize,
    onPageSizeChange = undefined,
    rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
    paginationMode = PaginationMode.CLIENT,
    rowCount,
  } = pagination || {};

  const { isError } = errorState || {};

  return (
    <Box sx={{ display: "flex", height: 1 }} data-testid={`${testID}-grid`}>
      <StyledDataGrid
        columns={cols}
        rows={data}
        loading={loading}
        error={isError ? true : undefined}
        page={page}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={rowsPerPageOptions}
        paginationMode={paginationMode}
        hideFooterPagination={hideFooterPagination}
        rowCount={rowCount}
        components={{
          Footer: customFooterContent ? Footer : GridFooter,
          Toolbar,
          ErrorOverlay: ErrorState,
          NoRowsOverlay: EmptyState,
          LoadingOverlay: LoadingState,
          // Pagination,
        }}
        componentsProps={{
          footer: {
            content: customFooterContent,
          },
          toolbar: {
            hideColumnSelector,
            hideExport,
            header,
            csvOptions,
          },
          errorOverlay: { ...errorState },
          noRowsOverlay: { ...emptyState },
          loadingOverlay: { data },
          // pagination: { data, loading, testID },
        }}
        getRowClassName={(data) =>
          `${data.row.warning ? "warning" : ""} ${
            data.row.danger ? "danger" : ""
          } ${data.indexRelativeToCurrentPage % 2 ? "even" : "odd"}`
        }
        initialState={{
          columns: { columnVisibilityModel: columnVisibility },
          sorting: {
            sortModel: sortModel || [{ field: "fullName", sort: "asc" }],
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        checkboxSelection={checkboxSelection}
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        disableColumnMenu={disableColumnMenu}
        onProcessRowUpdateError={(e) => console.error(e.message)}
        disableSelectionOnClick={disableSelectionOnClick}
      />
    </Box>
  );
};
