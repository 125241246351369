import React from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { endOfDay, parseISO } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CustomTextField, renderPickerDays } from "./CustomPicker";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  onChange: (value: Date | null) => void;
  pickerDays: Interval;
}

export const ControlledRangePicker = <T extends FieldValues>({
  name,
  label,
  control,
  onChange,
  pickerDays: { start, end },
}: Props<T>) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <MuiDatePicker
          label={label}
          value={endOfDay(parseISO(value as string))}
          onChange={onChange}
          renderDay={renderPickerDays({ start, end })}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="outlined"
              inputProps={{ ...params.inputProps, readOnly: true }}
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="small"
            />
          )}
          inputFormat="do MMM"
          showDaysOutsideCurrentMonth
          disableMaskedInput
        />
      )}
    />
  </LocalizationProvider>
);
