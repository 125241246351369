import React from "react";
import { TextButton } from "../../Buttons";
import { TooltipCell } from "./TooltipCell";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

export const ActionCell = ({ children, onClick, disabled }: Props) => (
  <TextButton
    onClick={onClick}
    disableFocusRipple
    sx={{
      flex: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: "auto",
    }}
    disabled={disabled}
    data-testid="grid-action-cell-button"
  >
    <TooltipCell>{children}</TooltipCell>
  </TextButton>
);
