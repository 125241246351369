import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  items: string[];
  testID: string;
};

export const Tiles = ({ items, testID }: Props) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: {
        sm: "repeat(3, 1fr)",
        md: "repeat(4, 1fr)",
        lg: "repeat(5, 1fr)",
        xl: "repeat(6, 1fr)",
      },
      alignItems: "center",
      gap: 2,
      mb: 1,
    }}
    data-testid={`${testID}-tiles`}
  >
    {items.map((item) => (
      <Box
        key={item}
        sx={{
          p: 2,
          display: "flex",
          bgcolor: "grey.100",
          borderRadius: 1,
        }}
        data-testid="tile-item"
      >
        <Typography variant="body1">{item}</Typography>
      </Box>
    ))}
  </Box>
);
