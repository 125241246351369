import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { differenceInBusinessDays, endOfDay, parseISO } from "date-fns";
import {
  DefaultModalProps,
  ModalSkeleton,
  ControlledRangePicker,
  TextField,
} from "../../../components";
import {
  Absence,
  CreateAbsenceInput,
  AbsenceApprovalStatus,
} from "../../../API";
import { User, useUser } from "../../../hooks";

const TEST_ID = "request-modal";
const DEFAULT_AMOUNT = 1;

type FormFields = CreateAbsenceInput;
type RangePickerFields = "startDate" | "endDate" | "amount";

type Props = DefaultModalProps & {
  title: string;
  onSubmit: ({ data }: { data: FormFields }) => void;
  testID: string;
  params: {
    row?: Absence;
    maxAmount: number;
  };
};

export const RequestModal: FC<Props> = ({
  title,
  onSubmit,
  testID,
  params: { row, maxAmount },
  ...rest
}) => {
  const user = useUser() as User;
  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: mapDefaultValues(row, user),
  });
  const getPickerDays = () => ({
    start: endOfDay(parseISO(getValues("startDate"))),
    end: endOfDay(parseISO(getValues("endDate"))),
  });
  const [saving, setSaving] = useState(false);
  const [pickerDays, setPickerDays] = useState<Interval>(getPickerDays());
  const maxAvailable = row?.amount ? row.amount + maxAmount : maxAmount;

  const postUpdate = () => {
    const { start, end } = getPickerDays();
    setValue("amount", differenceInBusinessDays(end, start) + 1);
    setPickerDays({ start, end });
  };

  const updateDates = (field: RangePickerFields, date: Date) => {
    setValue(field, date.toISOString());
    postUpdate();
  };

  const handleDateChange = (
    value: Date | string | null,
    field: RangePickerFields
  ) => {
    if (!value) return;
    const { start, end } = getPickerDays();
    updateDates(
      value > end ? "endDate" : value < start ? "startDate" : field,
      value as Date
    );
  };

  return (
    <ModalSkeleton
      {...rest}
      title={title}
      isForm
      primaryAction={{
        label: "Save",
        pending: saving,
        onSubmit: handleSubmit((data) => {
          data.amount = Number(data.amount);
          setSaving(true);
          return onSubmit({ data });
        }),
        testID: "save-request",
      }}
      testID={testID}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(3, 1fr)" },
          alignItems: "center",
          gap: 2,
          mb: 1,
        }}
      >
        <ControlledRangePicker
          control={control}
          name="startDate"
          label="Start Date"
          onChange={(date) => handleDateChange(date, "startDate")}
          pickerDays={pickerDays}
          data-testid={`${TEST_ID}-startDate-picker`}
        />
        <ControlledRangePicker
          control={control}
          name="endDate"
          label="End Date"
          onChange={(date) => handleDateChange(date, "endDate")}
          pickerDays={pickerDays}
          data-testid={`${TEST_ID}-endDate-picker`}
        />
        <TextField
          control={control}
          name="amount"
          label="Amount"
          type="number"
          inputProps={{ min: 0.25, step: 0.25, max: maxAvailable }}
          data-testid={`${TEST_ID}-amount`}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, 1fr)" },
          alignItems: "center",
          gap: 2,
          mb: 1,
        }}
      >
        <TextField
          control={control}
          name="description"
          label="Description"
          multiline
          rows={2}
          data-testid={`${TEST_ID}-description`}
          optional
          helperText="Use this field to provide additional information about your request, such as the allocation time for each day if you want to fractionate them."
        />
      </Box>
    </ModalSkeleton>
  );
};

const mapDefaultValues = (value?: FormFields, user?: User) => ({
  id: value?.id,
  startDate: value?.startDate ?? new Date().toISOString(),
  endDate: value?.endDate ?? new Date().toISOString(),
  amount: value?.amount ?? DEFAULT_AMOUNT,
  description: value?.description ?? "",
  employeeID: value?.employeeID ?? user?.id ?? "",
  absenceApproverId: null,
  approvalStatus: value?.approvalStatus ?? AbsenceApprovalStatus.pending,
});
