import React from "react";
import { Add as AddIcon, AlarmOff as TimeOffIcon } from "@mui/icons-material";
import { PageTitle, DataGrid, Tiles } from "../../components";
import { useResponsive } from "../../hooks";
import { useRequestModals } from "./Modals";
import { columns, hiddenColumnsMobile } from "./columns";
import { useTimeOffQuery } from "./useTimeOffQuery";

const TEST_ID = "time-off";

export const TimeOff = () => {
  const { showCreateModal, showUpdateModal, showDeleteModal } =
    useRequestModals();
  const { isMd } = useResponsive();

  const { items, error, isPending, daysUsed, vacationAllowance } =
    useTimeOffQuery();
  const remaining = (vacationAllowance ?? 0) - (daysUsed ?? 0);

  return (
    <>
      <PageTitle
        action={{
          label: "Request",
          icon: <AddIcon />,
          onClick: () => showCreateModal({ maxAmount: remaining }),
          disabled: remaining <= 0,
        }}
        icon={<TimeOffIcon />}
        title="Time Off"
        testID={TEST_ID}
      />
      <Tiles
        items={[
          `Allowance: ${vacationAllowance ?? 0}`,
          `Used: ${daysUsed ?? 0}`,
          `Remaining: ${remaining}`,
        ]}
        testID={TEST_ID}
      />
      <DataGrid
        columns={columns({ showUpdateModal, showDeleteModal, remaining })}
        data={items ?? []}
        loading={isPending}
        emptyState={{
          title: "Time Off",
          description: error?.message ?? "You have no time off requests.",
          testID: TEST_ID,
        }}
        hideColumnSelector
        hideExport
        testID={TEST_ID}
        disableColumnMenu
        hideFooterPagination
        disableSelectionOnClick
        columnVisibility={isMd ? hiddenColumnsMobile : {}}
        sortModel={[
          {
            field: "startDate",
            sort: "desc",
          },
        ]}
      />
    </>
  );
};
