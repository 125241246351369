export const listCompaniesWithEmployees = /* GraphQL */ `
  query ListCompaniesWithEmployees(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        inactiveAt
        country
        clients {
          items {
            id
            firstName
            lastName
            email
            inactiveAt
            employees {
              items {
                id
                clientID
                employeeID
                employee {
                  id
                  firstName
                  lastName
                  email
                  country
                  region
                  vacationAllowance
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listEmployeesWithCompanies = /* GraphQL */ `
  query ListEmployeesWithCompanies(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        inactiveAt
        country
        region
        vacationAllowance
        clients {
          items {
            id
            clientID
            employeeID
            client {
              id
              firstName
              lastName
              companyClientsId
              company {
                id
                name
                createdAt
                updatedAt
                country
              }
              email
              createdAt
              updatedAt
            }
            employee {
              id
              firstName
              lastName
              email
              country
              region
              vacationAllowance
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEmployeesWithTimelogs = /* GraphQL */ `
  query ListEmployeesWithTimelogs(
    $employeeID: ID = ""
    $limit: Int
    $nextToken: String
    $dateRange: [String] = null
    $logFilter: ModelTimelogFilterInput
    $nextTokenLogs: String
  ) {
    listEmployees(filter: {id: {contains: $employeeID}}, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        country
        region
        vacationAllowance
        clients {
          items {
            id
            clientID
            employeeID
            client {
              id
              firstName
              lastName
              companyClientsId
              company {
                id
                name
                createdAt
                updatedAt
                country
              }
              email
              createdAt
              updatedAt
            }
            employee {
              id
              firstName
              lastName
              email
              country
              region
              vacationAllowance
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        timelogs(date: {between: $dateRange}, filter: $logFilter, nextToken: $nextTokenLogs) {
          items {
            id
            approved
            onCall
            date
            standard
            overtime
            sick
            holiday
            notes
            clientID
            employeeID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployeeWithCompany = /* GraphQL */ `
  query GetEmployeeWithCompany(
    $id: ID!
    $dateRange: [String] = null
    $filter: ModelTimelogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEmployee(id: $id) {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      clients {
        items {
          id
          clientID
          client {
              id
              firstName
              lastName
              companyClientsId
              company {
                id
                name
                createdAt
                updatedAt
                country
              }
              email
              createdAt
              updatedAt
            }
          employeeID
          employee {
              id
              firstName
              lastName
              email
              country
              region
              vacationAllowance
              createdAt
              updatedAt
            }
          createdAt
          updatedAt
        }
        nextToken
      }
      timelogs(date: {between: $dateRange}, filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          approved
          onCall
          date
          standard
          overtime
          sick
          holiday
          notes
          employeeID
          clientID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listClientsWithTimelogs = /* GraphQL */ `
query ListClientsWithTimelogs(
  $filter: ModelClientFilterInput
  $employeeID: ID = ""
  $dateRange: [String] = null
) {
  listClients(filter: $filter) {
    items {
      id
      firstName
      lastName
      company {
        name
      }
      timelogs(filter: {and: {approved: {eq: true}, employeeID: {contains: $employeeID}}}, date: {between: $dateRange}) {
        items {
          id
          approved
          onCall
          date
          standard
          overtime
          sick
          holiday
          notes
          employeeID
          clientID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
}
`;

export const getEmployeeWithAbsences = /* GraphQL */ `
query GetEmployeeWithAbsences(
  $id: ID!
  $startDate: [String]!
) {
  getEmployee(id: $id) {
    id
    firstName
    lastName
    email
    country
    region
    vacationAllowance
    inactiveAt
    absences(startDate: {between: $startDate}) {
      items {
        id
        startDate
        endDate
        amount
        description
        approvalStatus
        employeeID
        createdAt
        updatedAt
        approver {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
