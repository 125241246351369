import { BaseRequestType } from "../apiService";
import { ClientMutation, ClientQuery, ClientReq, ClientVar } from "./clients";
import {
  CompanyMutation,
  CompanyQuery,
  CompanyReq,
  CompanyVar,
} from "./companies";
import {
  ContractsMutation,
  ContractsQuery,
  ContractsReq,
  ContractsVar,
} from "./contracts";
import {
  EmployeeMutation,
  EmployeeQuery,
  EmployeeReq,
  EmployeeVar,
} from "./employees";
import {
  TimelogMutation,
  TimelogQuery,
  TimelogReq,
  TimelogVar,
} from "./timelogs";
import {
  AbsenceMutation,
  AbsenceQuery,
  AbsenceReq,
  AbsenceVar,
} from "./absences";

export type QueryType = BaseRequestType &
  (
    | ClientQuery
    | EmployeeQuery
    | CompanyQuery
    | ContractsQuery
    | TimelogQuery
    | AbsenceQuery
  );

export type MutationType = BaseRequestType &
  (
    | ClientMutation
    | EmployeeMutation
    | CompanyMutation
    | ContractsMutation
    | TimelogMutation
    | AbsenceMutation
  );

export type ReqType<T> = T extends ClientQuery | ClientMutation
  ? ClientReq<T>
  : T extends EmployeeQuery | EmployeeMutation
  ? EmployeeReq<T>
  : T extends CompanyQuery | CompanyMutation
  ? CompanyReq<T>
  : T extends ContractsQuery | ContractsMutation
  ? ContractsReq<T>
  : T extends TimelogQuery | TimelogMutation
  ? TimelogReq<T>
  : T extends AbsenceQuery | AbsenceMutation
  ? AbsenceReq<T>
  : never;

export type VarType<T> = T extends ClientMutation
  ? ClientVar<T>
  : T extends EmployeeMutation
  ? EmployeeVar<T>
  : T extends CompanyMutation
  ? CompanyVar<T>
  : T extends ContractsMutation
  ? ContractsVar<T>
  : T extends TimelogMutation
  ? TimelogVar<T>
  : T extends AbsenceMutation
  ? AbsenceVar<T>
  : never;
