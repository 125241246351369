import React from "react";
import {
  styled,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    {...props}
    classes={{ popper: className }}
    data-testid="tooltip"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.common.black,
    fontSize: ".85rem",
    fontWeight: "400",
  },
}));
