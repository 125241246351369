import React, { ReactElement } from "react";
import {
  GridColDef,
  GridRenderCellParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { AbsenceApprovalStatus, Absence } from "../../API";
import { Tooltip } from "../../components";

type Actions = {
  showUpdateModal: () => void;
  showDeleteModal: () => void;
  remaining: number;
};

type RowAction = {
  label: string;
  icon: ReactElement;
  onClick: (params: GridRenderCellParams & { maxAmount?: number }) => void;
};

const displayStatus = (sts: string) => sts[0].toUpperCase() + sts.slice(1);

const disabledAction = (status: string) =>
  status !== AbsenceApprovalStatus.pending;

const renderActionButton = (
  { icon, label, onClick }: RowAction,
  params: GridRenderCellParams,
  remaining?: number
) =>
  disabledAction(params.row.approvalStatus) ? (
    <></>
  ) : (
    <Tooltip title={label} placement="top">
      <GridActionsCellItem
        label={label}
        onClick={() =>
          onClick({
            ...params,
            maxAmount: remaining,
          })
        }
        icon={icon}
        data-testid={`grid-${label.toLowerCase()}-button`}
      />
    </Tooltip>
  );

export const columns = ({
  showUpdateModal,
  showDeleteModal,
  remaining,
}: Actions): GridColDef<Absence>[] => [
  {
    field: "startDate",
    headerName: "Start",
    minWidth: 112,
    renderCell: ({ value }) => format(parseISO(value), "dd/MM/yyyy"),
  },
  {
    field: "endDate",
    headerName: "End",
    minWidth: 112,
    renderCell: ({ value }) => format(parseISO(value), "dd/MM/yyyy"),
  },
  {
    field: "amount",
    headerName: "Amount",
    type: "number",
  },
  {
    field: "approvalStatus",
    headerName: "Approval status",
    width: 128,
    renderCell: ({ value }) => displayStatus(value ?? "pending"),
  },
  {
    field: "approver",
    headerName: "Approver",
    flex: 1,
    renderCell: ({ value }) =>
      value ? `${value.firstName} ${value.lastName}` : "",
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellClassName: "actions",
    width: 96,
    sortable: false,
    headerAlign: "center",
    renderCell: (params) => (
      <>
        {renderActionButton(
          {
            label: "Edit",
            icon: <EditIcon />,
            onClick: showUpdateModal,
          },
          params,
          remaining
        )}
        {renderActionButton(
          {
            label: "Delete",
            icon: <DeleteIcon />,
            onClick: showDeleteModal,
          },
          params
        )}
      </>
    ),
  },
];

export const hiddenColumnsMobile: { [key: string]: boolean } = {
  endDate: false,
  amount: false,
  approver: false,
  description: true,
  actions: false,
};
